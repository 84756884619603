import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);

import FormBuilder from "lucien-form-builder";
Vue.use(FormBuilder);

import VueYouTubeEmbed from 'vue-youtube-embed'
Vue.use(VueYouTubeEmbed)

import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo)

import "./css/fonts.css";
import "./main.scss";

Vue.config.productionTip = false;

console.log("process.env.NODE_ENV", process.env.NODE_ENV, process.env);

import CopyDeck from '@/json/copydeck.js'

Vue.mixin({
  methods: {
    $CD(key) {
      return CopyDeck.get(key)
    }
  }
});

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");

const prod = process.env.NODE_ENV === 'production'
const shouldSW = 'serviceWorker' in navigator && prod
if (shouldSW) {
  navigator.serviceWorker.register('/service-worker.js').then(() => {
    console.log("Service Worker Registered!")
  })
}
